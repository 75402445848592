<template>
  <div class="bg-white py-4">
    <b-container fluid class="my-task py-4">
      <h1-tangerine :title="$t('my_tasks_title')" :includeLine="includeLine" />
      <div v-if="tasks === null" class="mt-3">
        <b-spinner large label="Large Spinner"></b-spinner>
      </div>
      <b-tabs pills class="my-task-navbar text-dark" :no-nav-style=true v-else-if="tasks.length > 0" lazy>
        <TasksTab v-for="(tab, index) in tabs" :key="index"
          :title="tab"
          :user="user"
          :tasks="getData(tab)"
        />
        <template #tabs-end v-if="user.band !== null">
          <li role="presentation" class="nav-item mb-2 flex-fill text-right">
            <router-link class="btn btn-outline-primary" :to="{ name:'accountLimits' }">{{ $t('view_account_limits') }}</router-link>
          </li>
        </template>
      </b-tabs>
      <div v-else>
        <div class="d-flex flex-column flex-1 align-items-center mb-4 mt-4">
          <img v-lazy="require('@/assets/Connecting/create-draft-task-icon.svg')" width="120" class="mx-auto mb-3 mt-1">
          <h4 class="font-weight-bold">{{ $t('seller_signup_success.create_draft.heading') }}</h4>
          <p class="w-75" v-html="$t('seller_signup_success.create_draft.copy')"/>
          <router-link :to="{ name:'createTask' }" rel="noopener">
            <button class="btn btn-primary px-5">
              {{ $t('seller_signup_success.create_draft.cta') }}
            </button>
          </router-link>
        </div>
      </div>
      </b-container>
  </div>
</template>

<script>
import ApiService from '@/apiService'
import TasksTab from '@/components/MyAccount/Tabs/TasksTab'
import H1Tangerine from '../../components/Global/H1Tangerine'

export default {
  name: 'Tasks',
  components: {
    TasksTab,
    H1Tangerine
  },
  data () {
    return {
      tabs: [
        'tasksAll',
        'tasksActive',
        'tasksPending',
        'tasksDraft',
        'tasksDeclined',
        'tasksUnlisted'
      ],
      user: null,
      tasks: null,
      includeLine: true
    }
  },
  created () {
    this.getTasks()
  },
  methods: {
    getTasks () {
      ApiService.getTasksByUser(localStorage.getItem('username')).then(response => {
        this.user = response.data.data.user
        this.tasks = response.data.data.gigs
        this.includeLine = response.data.data.gigs.length === 0
      })
    },
    getData (string) {
      return this[string]
    }
  },
  computed: {
    tasksAll: function () {
      return this.tasks.filter(function (task) {
        return task.status !== -1
      })
    },
    tasksDraft: function () {
      return this.tasks.filter(function (task) {
        return task.status === 0
      })
    },
    tasksActive: function () {
      return this.tasks.filter(function (task) {
        return task.status === 1
      })
    },
    tasksPending: function () {
      return this.tasks.filter(function (task) {
        return task.status === 2
      })
    },
    tasksDeclined: function () {
      return this.tasks.filter(function (task) {
        return task.status === 3
      })
    },
    tasksUnlisted: function () {
      return this.tasks.filter(function (task) {
        return task.status === 4
      })
    }
  }
}
</script>

<style lang="scss">
.my-task-navbar {
  .nav {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    width: auto;
    white-space: nowrap;
    .nav-item {
      a {
        color: #343a40;
      }
      a.active {
        color: #007bff;
      }
    }
  }
}

.line-holder {
  overflow: hidden;
  height: 20px;

  .line {
    height: 2px;
    background: rgb(240,127,19);
    background: linear-gradient(90deg, $brand-tangerine 0%, $brand-scarlet 100%);
    border-radius: 4px;

    &.glow-line {
      filter: blur(4px);
      border-radius: 4px;
      height: 2px;
      width: 99%;
      margin: -1px 0 auto;
      opacity: .9;
    }
  }
}
</style>
